import React from 'react';
import AuthLayout from 'components/AuthLayout';
import ResetPasswordPage from 'views/ForgotPasswordPage/ResetPasswordConfirmPage';

const ResetPassword = () => {
  return (
    <AuthLayout>
      <ResetPasswordPage />
    </AuthLayout>
  );
};

export default ResetPassword;
