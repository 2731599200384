import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import notify from 'notify';
import Button from 'components/Button';
import Input from 'components/Input';
import { resetPasswordConfirm } from './api';
import { StyledResetPasswordPage } from './styles';

const ResetPasswordPage = () => {
  const { search } = useLocation();
  const key = new URLSearchParams(search).get('key');

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setError
  } = useForm();

  const submitForm = async values => {
    try {
      await resetPasswordConfirm({ password: values.password, key: key as string });
      notify('Your password has been successfully reset');
      navigate('/login', { replace: true });
    } catch (err) {
      const passwordError = err.response?.params?.password?.[0];
      if (passwordError) setError('password', { type: 'manual', message: passwordError });
      else notify(err.message);
    }
  };

  return (
    <StyledResetPasswordPage>
      <h1 className="title">Create new password</h1>
      <form onSubmit={handleSubmit(submitForm)}>
        <Input
          {...register('password', { required: 'Required' })}
          type="password"
          label="Create Password"
          placeholder="Enter Password"
          error={errors.password?.message}
        />
        <br />
        <Input
          {...register('passwordConfirm', {
            required: 'Required',
            validate: value => value === getValues('password') || `Passwords don't match`
          })}
          type="password"
          label="Confirm Password"
          placeholder="Confirm Password"
          error={errors.passwordConfirm?.message}
        />
        <Button className="button-submit">Submit</Button>
      </form>
    </StyledResetPasswordPage>
  );
};

export default ResetPasswordPage;
